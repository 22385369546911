$mainFontFamily: 'Nunito';

@font-face {
    font-family: $mainFontFamily;
    src:
            local('MainFont'),
            url("../../shared/assets/fonts/Nunito-Regular.ttf") format('ttf'),
            url("../../shared/assets/fonts/Nunito-Regular.woff") format('woff'),
            url("../../shared/assets/fonts/Nunito-Regular.woff2") format('woff2');
    font-weight: 400;
}

@font-face {
  font-family: $mainFontFamily;
  src:
          local('MainFont'),
          url("../../shared/assets/fonts/Nunito-Bold.ttf") format('ttf'),
          url("../../shared/assets/fonts/Nunito-Bold.woff") format('woff'),
          url("../../shared/assets/fonts/Nunito-Bold.woff2") format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: $mainFontFamily;
  src:
          local('MainFont'),
          url("../../shared/assets/fonts/Nunito-ExtraBold.ttf") format('ttf'),
          url("../../shared/assets/fonts/Nunito-ExtraBold.woff") format('woff'),
          url("../../shared/assets/fonts/Nunito-ExtraBold.woff2") format('woff2');
  font-weight: 800;
}

html {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100dvh;
}

body {
    position: relative;
    margin: 0;
    font-family: $mainFontFamily, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100dvh;
    background: rgba(23, 30, 39, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  @media (min-width: 320px) {
    padding-left: 7.69%;
    padding-right: 7.69%;
  }
  @media (min-width: 768px) {
    padding-right: 7.8%;
    padding-left: 7.8%;
  }
  @media (min-width: 1200px) {
    padding-right: toRem(80);
    padding-left: toRem(80);
  }
  @media (min-width: 1300px) {
      padding-right: 0;
      padding-left: 0;
  }
}