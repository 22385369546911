@import "src/app/styles/index.scss";

.root {
    margin-top: 90px;

    @include media-tablet() {
        margin-top: 140px;
    }

    @include media-desktop() {
        margin-top: 200px;
    }
}

.title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 800;

    @include media-tablet() {
        font-size: 32px;
        line-height: 36px;
    }
}

.description {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    margin-top: 40px;

    @include media-tablet() {
        font-size: 24px;
        line-height: 32px;

        margin-top: 50px;
    }
}

.form-wrapper {
    width: 100%;
    margin-top: 20px;

    @include media-tablet() {
        margin-top: 50px;
    }

    @include media-large() {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}

.form {
    display: flex;
    flex-direction: column;

    @include media-desktop() {
        flex-direction: row;
        justify-content: space-between;
    }
}

.inputs {
    width: 100%;

    @include media-desktop() {
        width: calc(100% - 15px);
    }
}

.input {
    width: 100%;

    & + & {
        margin-top: 20px;
    }

    @include media-tablet() {
        & + & {
            margin-top: 30px ;
        }
    }
}

.textarea {
    width: 100%;

    margin-top: 20px;

    @include media-tablet() {
        margin-top: 30px;
    }

    @include media-desktop() {
        margin-top: 0;
        width: calc(100% - 15px);
        margin-left: 30px;
    }
}

.button {
    display: block;
    min-width: 260px;
    padding-left: 28px;
    padding-right: 28px;
    margin: 0 auto;
    margin-top: 60px;

    @include media-tablet() {
        margin-top: 80px;
    }

    @include media-desktop() {
        margin-left: 0;
        margin-right: 0;
    }
}