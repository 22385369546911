@import "src/app/styles/mixins.scss";

@keyframes room-layer-2 {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-3 {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-4 {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-5 {
    0% {
        opacity: 0;
    }
    35% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-6 {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-7 {
    0% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes room-layer-8 {
    0% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;

    @include media-tablet() {
        padding-top: 140px;
    }

    @include media-desktop() {
        padding-top: 180px;
        flex-direction: row;
        justify-content: space-between;
    }
}

.info {
    width: 100%;

    @include media-desktop() {
        width: 460px;
    }
}

.title {
    color: #CA47D6;

    font-size: 24px;
    line-height: 36px;
    font-weight: 800;

    @include media-tablet() {
        font-size: 32px;
        line-height: 36px;
    }
}

.description {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    margin-top: 40px;

    @include media-tablet() {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;

        margin-top: 50px;
    }
}

.room {
    position: relative;
    width: 100%;
    aspect-ratio: 1;

    margin-top: 18px;

    @include media-tablet() {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
    }

    @include media-desktop() {
        margin-top: 0;
    }

    & picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        &:nth-child(2) {
            animation-name: room-layer-2;
        }

        &:nth-child(3) {
            animation-name: room-layer-3;
        }

        &:nth-child(4) {
            animation-name: room-layer-4;
        }

        &:nth-child(5) {
            animation-name: room-layer-5;
        }

        &:nth-child(6) {
            animation-name: room-layer-6;
        }

        &:nth-child(7) {
            animation-name: room-layer-7;
        }

        &:nth-child(8) {
            animation-name: room-layer-8;
        }
    }
}