@import "/src/app/styles/index.scss";

.root {
    width: 100%;
    overflow: hidden;
    background-color: #171E27;
    border-radius: 16px;
    box-sizing: border-box;

    transition: 0.3s ease;
    transition-property: border-color;

    &.is-active {
        cursor: pointer;

        border: 1px solid transparent;

        &:hover {
            .button {
                opacity: 1;
                pointer-events: all;
            }

            .avatar {
                & img {
                    transform: scale(1.2) translate(-40%, -40%);
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &.is-selected {
        border: 1px solid #FFFFFF;
    }

}
.is-standart {
    & .id,
    & .gen,
    & .rarity {
        color: #FFFFFF;
    }
}

.is-silver {
    & .id,
    & .gen,
    & .rarity {
        color: #D3BAAC;
    }
}

.is-gold {
    & .id,
    & .gen,
    & .rarity {
        color: #FFC940;
    }
}

.is-diamond {
    & .id,
    & .gen,
    & .rarity {
        color: #8CCFFF;
    }
}

.is-na {
    & .id,
    & .gen,
    & .rarity {
        color: #3D4E64;
    }
}

.avatar {
    position: relative;
    overflow: hidden;

    width: 100%;
    aspect-ratio: 1;

    &::after {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.6) 48%, rgba(255, 255, 255, 0.2) 100%);

        opacity: 0;

        transition: 0.3s ease;
        transition-property: opacity;
    }

    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);

        transition: 0.3s ease;
        transition-property: transform;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    box-sizing: border-box;

    @include media-tablet() {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (min-width: 1920px) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.first-row {
    margin-top: 15px;
    margin-bottom: 10px;

    @include media-tablet() {
        margin-top: 20px;
        margin-bottom: 12px;
    }

    @media (min-width: 1920px) {
        margin-top: 24px;
        margin-bottom: 16px;
    }
}

.second-row {
    position: relative;
    padding-bottom: 14px;

    @include media-tablet() {
        padding-bottom: 20px;
    }

    @media (min-width: 1920px) {
        padding-bottom: 24px;
    }
}

.id,
.gen,
.rarity {
    display: block;

    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.icon {
    width: 16px;

    @media (min-width: 1920px) {
        width: 20px;
    }
}

.button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 2;

    border: none;
    outline: none;

    opacity: 0;
    pointer-events: none;

    transition: 0.3s ease;
    transition-property: opacity;

    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 110%;
        height: 100%;
        object-fit: cover;
    }

    & p {
        position: relative;
        z-index: 2;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: #FFF;
    }
}