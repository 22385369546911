@import "src/app/styles/index.scss";

@keyframes active-slide-animation {
    from {
        transform: scale(0.85);
        opacity: 0.35;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes inactive-slide-animation {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.85);
        opacity: 0.35;
    }
}

.root {
    margin-top: 30px;
}

.tabs {
    margin-bottom: 24px;
    padding-left: 7.69%;
    padding-right: 7.69%;

    @include media-tablet() {
        margin-bottom: 36px;
        padding: 0;

        & div {
            display: flex;
            justify-content: center;
            margin: 0 auto;

            & div {
                margin: 0;
            }
        }
    }

    @include media-desktop() {
        margin-bottom: 60px;
    }
}

.tab-slide {
    height: 50px;

    @include media-tablet() {
        width: 120px!important;
    }
}

.tab {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    border-radius: 12px;

    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    font-family: $mainFontFamily;
    color: #4C5D73;
    background: none;
    border: none;
    outline: none;

    transition: 0.3s ease;
    transition-property: color;

    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #7529bb 0%, #00b2ff 46%, #e256b7 100%);

        opacity: 0;

        transition: 0.3s ease;
        transition-property: opacity;
    }

    &:not(.is-active) {
        &:hover {
            color: #FFFFFF;
        }
    }

    &.is-active {
        color: #FFFFFF;
        font-weight: 700;

        &::before {
            opacity: 1;
        }
    }
}

.card-slider {
    width: 100%;
    // flex: 1;

    @include media-tablet() {
        width: 600px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: 0.3s ease;
    transition-property: transform, opacity;

    &.is-active {
        .card-title {
            opacity: 1;
        }
    }

    &.view {
        &-0 {
            $color: #4BF648;


            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                    border: 5px solid red;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }

        &-1 {
            $color: #FF76D9;

            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }

        &-2 {
            $color: #40FFF4;

            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }

        &-3 {
            $color: #FFC940;

            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }

        &-4 {
            $color: #4A93FF;

            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }

        &-5 {
            $color: #C070FF;

            .card-article-title {
                color: $color;
            }

            .quantity {
                color: $color;

                & input {
                    border: 1px solid $color;
                }

                @include media-tablet() {
                    & input {
                        border: 2px solid $color;
                    }
                }
            }

            .currency {
                color: $color;
            }

            .currency-tabs {
                & button {
                    color: $color;
                    border: 2px solid $color;
                }
            }

            .mobile-currency-tabs {
                & button {
                    color: $color;
                    border: 1px solid $color;
                }
            }
        }
    }

    @include media-desktop() {
        transform: scale(0.85);
        opacity: 0.35;

        &.is-active {
            animation-name: active-slide-animation;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-timing-function: linear;
        }
    }
}

.card-title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 17px;
    text-align: center;

    opacity: 0;

    transition: 0.3s ease;
    transition-property: opacity;

    @include media-tablet() {
        font-size: 32px;
        line-height: 44px;
    }
}

.card-article {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100vw - 60px);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 35px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.15) 0%, rgba(97, 67, 67, 0.15) 29%, rgba(255, 255, 255, 0.15) 48.5%, rgba(57, 57, 57, 0.15) 73%, rgba(115, 115, 115, 0.15) 100%);

    padding: 30px;
    box-sizing: border-box;

    @include media-tablet() {
        width: 600px;
        padding: 50px 90px;
    }
}

.card-article-title {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    font-weight: 800;
    margin-bottom: 12px;

    @include media-tablet() {
        font-size: 32px;
        line-height: 36px;
    }
}

.card-article-alert {
    font-size: 16px;
    line-height: 19px;
    font-weight: 800;
    color: #FF3737;
    text-align: center;
    margin-bottom: 30px;
    min-height: 20px;

    @include media-tablet() {
        font-size: 22px;
        line-height: 36px;
        min-height: 36px;
    }
}

.card-article-boy {
    width: 83px;
    margin: 0 auto;
    margin-bottom: -10px;
    margin-top: 15px;
    position: relative;
    z-index: 2;

    @include media-tablet() {
        width: 130px;
        margin-bottom: -13px;
    }
}

.card-article-nfts {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & picture {
        &:nth-child(1) {
            width: 70px;
            height: 70px;
        }

        &:nth-child(2) {
            width: 100px;
            height: 100px;
            margin-left: 5px;
            margin-right: 5px;
        }

        &:nth-child(3) {
            width: 70px;
            height: 70px;
        }
    }

    @include media-tablet() {
        & picture {
            &:nth-child(1) {
                width: 120px;
                height: 120px;
            }
    
            &:nth-child(2) {
                width: 160px;
                height: 160px;
                margin-left: 10px;
                margin-right: 10px;
            }
    
            &:nth-child(3) {
                width: 120px;
                height: 120px;
            }
        }
    }
}

.card-radial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 463px;
    z-index: -1;

    @include media-tablet() {
        width: 713px;
    }
}

.card-article-description {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    margin-top: 40px;

    @include media-tablet() {
        font-size: 20px;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
        margin-top: 40px;
    }
}

.quantity {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 35px;

    & p {
        display: block;
        width: 93px;
        font-size: 16px;
        line-height: 17px;
        font-weight: 700;
        color: inherit;
    }

    & input {
        font-size: 16px;
        line-height: 28px;
        font-weight: 700;
        font-family: $mainFontFamily;
        box-sizing: border-box;
        text-align: center;
        width: 52px;
        height: 30px;
        background: none;
        margin-left: 10px;
        border-radius: 6px;
        color: inherit;
        outline: none;

        &:disabled {
            opacity: 1;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    @include media-tablet() {
        margin-top: 30px;

        & p {
            width: 130px;
            font-size: 22px;
            line-height: 32px;
        }

        & input {
            width: 60px;
            height: 40px;
            font-size: 22px;
            line-height: 40px;
            margin-left: 20px;
            border-radius: 12px;
        }
    }
}

.form-wrapper {
    width: fit-content;
    margin: 0 auto;

    @include media-tablet() {
        width: 100%;
    }
}

.currency {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 15px;

    & p {
        display: block;
        width: 93px;
        font-size: 16px;
        line-height: 17px;
        font-weight: 700;
        color: inherit;
    }

    @include media-tablet() {
        & p {
            width: 130px;
            font-size: 22px;
            line-height: 32px;
        }
    }
}

.currency-tabs {
    display: none;

    @include media-tablet() {
        display: flex;
        align-items: center;
        margin-left: 10px;

        & button {
            cursor: pointer;

            position: relative;
            padding: 2px 6px;
            box-sizing: border-box;

            font-size: 22px;
            line-height: 32px;
            font-family: $mainFontFamily;
            font-weight: 700;
            margin-left: 10px;

            transition: 0.3s ease;
            transition-property: color, border-color;

            background: none;
            border-radius: 12px;

            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: linear-gradient(135deg, #7529bb 0%, #00b2ff 46%, #e256b7 100%);
                opacity: 0;

                transition: 0.3s ease;
                transition-property: opacity;
            }

            &.is-active {
                color: #FFFFFF!important;
                border: 2px solid #FFFFFF!important;

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

.mobile-currency-tabs {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;

    & button {
        position: relative;
        padding: 6px 9px;
        box-sizing: border-box;

        font-size: 16px;
        line-height: 16px;
        font-family: $mainFontFamily;
        font-weight: 700;

        margin-right: 10px;

        transition: 0.3s ease;
        transition-property: color, border-color;

        background: none;
        border-radius: 6px;

        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(135deg, #7529bb 0%, #00b2ff 46%, #e256b7 100%);
            opacity: 0;

            transition: 0.3s ease;
            transition-property: opacity;
        }

        &.is-active {
            color: #FFFFFF!important;
            border: 1px solid #FFFFFF!important;

            &::after {
                opacity: 1;
            }
        }
    }

    @include media-tablet() {
        display: none;
    }
}

.mobile-currency-tabs-icon {
    width: 20px;
    height: 20px;
}

.button {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    @include media-tablet() {
        margin-top: 45px;
    }
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 24px;

    @include media-tablet() {
        margin-bottom: 44px;
    }

    @include media-desktop() {
        margin-bottom: 40px;
    }
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4C5D73;

    & + & {
        margin-left: 12px;
    }

    transition: 0.3s ease;
    transition-property: background-color;

    &.is-active {
        background-color: #FFFFFF;
    }
}

.opensea {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
        font-weight: 700;
    }

    & a {
        display: block;
        text-decoration: none;
        width: 42px;
        height: 42px;
        margin-left: 16px;

        & img {
            width: 100%;
            height: 100%;
        }
    }

    @include media-tablet() {
        & p {
            font-size: 24px;
            line-height: 33px;
        }

        & a {
            width: 56px;
            height: 56px;
        }
    }
}