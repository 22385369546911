@import "src/app/styles/mixins.scss";

.root {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &.is-open {
        pointer-events: auto;

        @include media-tablet() {
            z-index: 20;
        }
    }

    &.is-upper-header {
        z-index: 20;
    }
}

.overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: #171E27;
    backdrop-filter: blur(6px);

    transition: 0.3s ease;
    transition-property: opacity;

    opacity: 0;

    &.is-open {
        opacity: 0.9;

        transition: 0.3s ease;
        transition-property: opacity;
    }
}

.card {
    transform: translateY(100%);
    opacity: 0;

    transition: 0.3s ease;
    transition-property: transform, opacity;

    &.is-open {
        transform: translateY(0);
        opacity: 1;
    }
}