@import "src/app/styles/index.scss";

.card {
    width: 100vw;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-tablet() {
        width: 468px;
        padding-left: 0;
        padding-right: 0;
    }
}

.close {
    position: absolute;
    top: 20px;
    right: 22px;
    width: 25px;
    height: 25px;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    z-index: 20;
    cursor: pointer;

    opacity: 0;

    transition: 0.3s ease;
    transition-property: opacity;

    &.is-open {
        opacity: 1;
    }

    & img {
        width: 100%;
    }

    @include media-tablet() {
        top: 40px;
        right: 40px;
    }
}

.boy {
    position: relative;
    z-index: 10;
    width: 154px;
    margin-bottom: -9px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, rgba(219, 168, 208, 0.7) 0%, rgba(146, 160, 210, 0.7) 100%);

    width: 100%;
    box-sizing: border-box;

    padding: 15px 20px;
    border-radius: 12px;

    font-size: 16px;
    line-height: 16px;
    font-weight: 700;

    @include media-tablet() {
        padding: 15px 30px;
    }

    & .row {
        display: flex;
        align-items: center;

        & picture {
            width: 30px;
            height: auto;
            margin-right: 24px;
        }
    }
}

.sale-section {
    padding: 12px 20px;
    border-radius: 12px;
    background: linear-gradient(315deg, rgba(53, 69, 90, 0.7) 0%, rgba(73, 92, 117, 0.7) 100%);

    width: 100%;
    box-sizing: border-box;

    & .text {
        font-size: 16px;
        line-height: 16px;
        color: #7D91AB;
    }

    & .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
    }

    & button {
        outline: none;
        border: none;
        background: none;
        font-size: 16px;
        line-height: 16px;
        color: #7D91AB;
        cursor: pointer;
    }

    margin-top: 6px;
}

.select {
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    background-color: #1B2838;
    border-radius: 17px;

    min-width: 105px;

    &.is-active {
        .dropdown {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
        }
    }
}

.input {
    background: none;
    color: #fff;
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
    border: none;
    outline: none;
    width: 70%;
    font-family: $mainFontFamily!important;
    text-align: left;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
}

.select-wrapper {
    display: flex;
    align-items: center;
}

.select-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.select-title {
    font-size: 18px;
    line-height: 18px;
    color: #fff;
    font-weight: 700;
    margin-right: 10px;
}

.chevron {
    width: 15px;
    height: 15px;
}

.dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background-color: #1B2838;
    border-radius: 17px;
    pointer-events: none;

    transform: translateY(-20px);
    opacity: 0;

    transition: 0.3s ease;
    transition-property: transform, opacity;
}

.dropdown-item {
    display: flex;
    align-items: center;
    
    & picture {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    & p {
        font-size: 18px;
        line-height: 18px;
        color: #fff;
        font-weight: 700;
    }

    & + & {
        margin-top: 5px;
    }
}

.button {
    width: 100%;
    margin-top: 6px;
}