@import "src/app/styles/index.scss";

.root {
    display: block;
    width: 30px;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin-top: -6px;

    & span {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        margin-top: 6px;

        transition: 0.3s ease;
        transition-property: transform, opacity;
    }

    @include media-tablet() {
        width: toRem(36);
    }
}

.is-active {
    & span {
        &:nth-child(1) {
            transform: rotate(45deg) translateY(7px) translateX(4px);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg) translateY(-7px) translateX(4px);
        }
    }
}