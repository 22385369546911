@import "src/app/styles/mixins.scss";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 90px;

    @include media-tablet() {
        margin-top: 140px;
    }

    @include media-desktop() {
        margin-top: 200px;
        flex-direction: row;
        justify-content: space-between;
    }
}

.wrapper {
    width: 100%;

    @include media-desktop() {
        width: 480px;
    }
}

.title {
    color: #0085FF;
    font-size: 24px;
    line-height: 36px;
    font-weight: 800;

    @include media-tablet() {
        font-size: 32px;
        line-height: 36px;
    }
}

.description {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    margin-top: 40px;

    @include media-tablet() {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;

        margin-top: 50px;
    }
}

.image {
    position: relative;
    width: 100%;
    aspect-ratio: 1.04;
    margin-top: 60px;
    margin-left: 5%;

    @include media-tablet() {
        width: 520px;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        margin-left: 0;
    }

    @include media-desktop() {
        margin-top: 0;
        margin-right: 0;
    }
}

.image-left {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    z-index: 2;

    transition: 0.6s ease;
    transition-property: opacity, transform;
    transition-delay: 1s;

    transform: translateY(100px);
    opacity: 0;

    &.is-active {
        transform: translateY(0);
        opacity: 1;
    }
}

.image-right {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;
    width: auto;
    z-index: 1;

    transition: 0.6s ease;
    transition-property: opacity, transform;
    transition-delay: 1s;

    transform: translateX(100px);
    opacity: 0;

    &.is-active {
        transform: translateX(0);
        opacity: 1;
    }
}