@import "src/app/styles/mixins.scss";

.root {
    margin-top: 90px;

    @include media-tablet() {
        margin-top: 140px;
    }

    @include media-desktop() {
        margin-top: 180px;
    }
}
