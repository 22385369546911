@import "/src/app/styles/index.scss";

.root {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @include media-desktop() {
        margin-top: 0;
        flex-direction: row;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 1920px) {
        align-items: flex-end;
        width: calc(100% - 420px);
    }
}

.balance {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 40px;

    & img {
        width: 40px;
    }

    & p {
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0.08em;

        margin-left: 10px;

        color: #fff;
    }

    @include media-tablet() {
        margin-top: 50px;
    }

    @media (min-width: 1920px) {
        display: none;
    }
}