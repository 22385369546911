@import "src/app/styles/index.scss";

.root {
    position: relative;
    overflow: hidden;

    width: 100%;
    margin: 0 auto;
    background-color: #222B37;
    margin-bottom: 50px;
    aspect-ratio: 0.75;

    @include media-tablet() {
        margin-bottom: 70px;
        aspect-ratio: 1;
    }

    @include media-desktop() {
        width: 100%;
        aspect-ratio: auto;
        height: 450px;
        box-sizing: border-box;
        max-width: 1200px;
        border-radius: 64px;
    }
}

.video {
    width: 100%;
}