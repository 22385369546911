@import "src/app/styles/mixins.scss";

.root {
    position: relative;
}

.large {
    display: none;

    @include media-desktop() {
        display: block;
        position: absolute;
        top: -50%;
        left: 35%;
        transform: translateX(-50%);
        width: 2733px;
        z-index: -1;
    }
}

.small {
    display: none;

    @include media-tablet() {
        display: block;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        width: 1310px;
        z-index: -1;
    }

    @include media-desktop() {
        display: none;
    }
}