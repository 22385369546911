@import "src/app/styles/index.scss";

.root {
    margin-top: 90px;

    @include media-tablet() {
        margin-top: 140px;
    }

    @include media-desktop() {
        margin-top: 180px;
    }
}

.title {
    color: #8CCFFF;
    font-size: 24px;
    line-height: 36px;
    font-weight: 800;

    @include media-tablet() {
        font-size: 32px;
    }
}

.description {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    margin-top: 40px;

    @include media-tablet() {
        margin-top: 50px;
        font-size: 24px;
        line-height: 32px;
    }
}