@import "src/app/styles/index.scss";

.root {
    position: relative;
    width: 100%;
    z-index: 2;
}

.background-1 {
    position: absolute;
    width: 1517px;
    top: -300px;
    left: -800px;
    z-index: -2;
    transform: rotate(-90deg);

    @include media-tablet() {
        width: 1600px;
        top: -900px;
        left: -400px;
        transform: rotate(0);
    }

    @include media-desktop() {
        width: 100%;
        max-width: 1920px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
}

.background-2 {
    position: absolute;
    width: 1000px;
    top: 1000px;
    left: -200px;
    z-index: -2;

    @include media-tablet() {
        position: absolute;
        top: 1000px;
        left: -600px;
        width: 1700px;
        z-index: -1;
    }

    @include media-desktop() {
        display: none;
    }
}