@import "src/app/styles/index.scss";

.parent {
    margin-top: 30px;

    @include media-tablet() {
        margin-top: 80px;
    }
}

.root,
.bg-wrapper,
.content-wrapper {
    position: relative;
    width: 100vw;

    @include media-desktop() {
        height: 600px;
    }
}

.bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;

        opacity: 0;
        filter: blur(2px);

        transition: 1s ease;
        transition-property: opacity, filter;
    }

    & picture.is-active {
        opacity: 1;
        filter: blur(0);
    }
}

.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 40px;

    @include media-tablet() {
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 80px;
    }

    @include media-desktop() {
        flex-direction: row;
        justify-content: center;
    }
}

.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-max-mobile() {
        & + & {
            margin-top: 14px;
        }
    }

    @include media-tablet() {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;

        & + & {
            margin-top: 30px;
        }
    }

    @include media-desktop() {
        width: auto;
        justify-content: center;

        & + & {
            margin-top: 0;
            margin-left: 32px;
        }
    }
}

.tab {
    background: none;
    outline: none;
    border: none;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
    font-family: $mainFontFamily;

    color: #FFFFFF;

    transition: 0.3s ease;
    transition-property: color;

    &.is-active {
        color: #FFC940;
    }

    @include media-max-mobile() {
        & + & {
            margin-top: 14px;
        }
    }

    @include media-tablet() {
        font-size: 24px;
        line-height: 32px;
    }

    @include media-desktop() {
        & + & {
            margin-left: 32px;
        }
    }
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    padding-bottom: 50px;

    box-sizing: border-box;

    @include media-tablet() {
        padding-top: 50px;
        padding-bottom: 112px;
    }

    @include media-desktop() {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-desktop() {
        flex-direction: row;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.4);
}

.content {
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media-desktop() {
        flex-direction: row;
    }
}

.room {
    width: 360px;
    height: 360px;

    transition: 0.3s ease;
    transition-property: transform;
    transform: scale(0);
    transition-delay: 1000ms;

    &.is-active {
        transform: scale(1);
    }

    @include media-tablet() {
        width: 500px;
        height: 500px;
    }
}

.card {
    border-radius: 32px;
    background: linear-gradient(315deg, rgba(12, 17, 22, 0.7) 0%, rgba(77, 78, 89, 0.7) 100%);
    padding: 45px 100px;

    @media (max-width: 768px) {
        padding: 35px 60px;
    }


    @media (max-width: 472px) {
        padding: 35px 25px;
    }

    font-size: 16px;
    line-height: 32px;
    font-weight: 700;

    color: #FFC940;


    .is-standart {
        color: white;
    }
    .is-silver {
        color: #D3BAAC;
    }
    .is-gold {
        color: #FFC940;
    }
    .is-diamond {
        color: #55BEFF;
    }

    transition: 0.3s ease;
    transition-property: transform, opacity;
    transform: translateX(50%);
    opacity: 0;
    transition-delay: 1000ms;

    &.is-active {
        transform: translateX(0);
        opacity: 1;
    }

    .card-text {
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include media-max-mobile() {

        // margin-left: 30px;
        // margin-right: 30px;
    }

    @include media-tablet() {
        // padding: 55px 75px;

        font-size: 24px;
        line-height: 48px;
        margin-top: 50px;
    }

    @include media-desktop() {
        margin-top: 0;
        margin-left: 83px;
    }
}